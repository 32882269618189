<!-- navigation bar -->
<app-navbar></app-navbar>
<main>
    <section>
        <div class="container-fluid" style="background-image: url(/assets/img/testibg.jpg); padding: 50px; background-repeat: no-repeat; background-size: cover;">
            <div class="row header-section">
                <h2 class="heading">Pricing Table</h2>
                <br>
                <h4 class="sub-heading">Home > Pricing Table</h4>
            </div>
        </div>
        <div class="container" style="margin-top: 80px;">
            <ol class="breadcrumb breadcrumb-arrow">
                <li><a [routerLink]="['/home']">Home</a></li>
                <li class="active"><span>Pricing Table</span></li>
            </ol>
        </div>
    </section>
    <br>
    <section>
        <div class="container">
            <div class="row">
                <table class="table table-striped mx-auto shadow p-2 mb-3">
                    <colgroup></colgroup>
                    <colgroup></colgroup>
                    <colgroup></colgroup>
                    <colgroup></colgroup>
                    <thead>
                      <tr>
                        <th class="pricingth" scope="col">
                          <img class="img-fluid" src="/assets/img/benefits.jpg" alt="">
                        </th>
                        <th scope="col" class="hoverPrice">
                            <div style="display: block; text-align: center; bottom: 50px; position: relative;">
                                <button class="btn btn-primary" style="margin-bottom: 15px;">Wallet</button>
                                <h3 style="font-size: medium; font-weight: 550; color: #5c636a;">Lorem Ipsum</h3>
                                <h1 style="font-size: x-large; font-weight: 600; color: #5c636a;">&#8377; 2000 <span style="font-size: medium; font-weight: 500; color: #5c636a;"> + GST</span></h1>
                                <button class="btn btn-outline-success">GET START</button>
                            </div>
                        </th>
                        <th class="tablehead" scope="col" class="hoverPrice">
                            <div style="display: block; text-align: center; bottom: 50px; position: relative;">
                                <button class="btn btn-primary" style="margin-bottom: 15px;">Fixed</button>
                                <h3 style="font-size: medium; font-weight: 550; color: #5c636a;">Lorem Ipsum</h3>
                                <h1 style="font-size: x-large; font-weight: 600; color: #5c636a;">&#8377; 3000 <span style="font-size: medium; font-weight: 500; color: #5c636a;"> + GST</span></h1>
                                <button class="btn btn-outline-success">GET START</button>
                            </div>
                        </th>
                        <th class="tablehead" scope="col" class="hoverPrice">
                            <div style="display: block; text-align: center; bottom: 50px; position: relative;">
                                <h4 class="popular">Popular</h4>
                                <button class="btn btn-primary" style="margin-bottom: 15px;">Corporate</button>
                                <h3 style="font-size: medium; font-weight: 550; color: #5c636a;">Lorem Ipsum</h3>
                                <h1 style="font-size: x-large; font-weight: 600; color: #5c636a;">&#8377; 5000 <span style="font-size: medium; font-weight: 500; color: #5c636a;"> + GST</span></h1>
                                <button class="btn btn-outline-success active">GET START</button>
                            </div>
                        </th>
                        <th class="tablehead" scope="col" class="hoverPrice">
                            <div style="display: block; text-align: center; bottom: 50px; position: relative;">
                                <button class="btn btn-primary" style="margin-bottom: 15px;">Enterprise</button>
                                <h3 style="font-size: medium; font-weight: 550; color: #5c636a;">Lorem Ipsum</h3>
                                <h1 style="font-size: x-large; font-weight: 600; color: #5c636a;">&#8377; 12000 <span style="font-size: medium; font-weight: 500; color: #5c636a;"> + GST</span></h1>
                                <button class="btn btn-outline-success">GET START</button>
                            </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                      <tr>
                        <th scope="row">Get top up of</th>
                        <td style="font-size: large; font-weight: 600; color: #5c636a;">&#8377; 2000</td>
                        <td style="font-size: large; font-weight: 600; color: #5c636a;">&#8377; 3000</td>
                        <td style="font-size: large; font-weight: 600; color: #5c636a;">&#8377; 5000</td>
                        <td style="font-size: large; font-weight: 600; color: #5c636a;">&#8377; 12000</td>
                      </tr>
                      <tr>
                        <th scope="row">Best for Hairing</th>
                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                      </tr>
                      <tr>
                        <th scope="row">Expected Candidate Responses</th>
                        <td>Appear in general results</td>
                        <td>Appear in general results</td>
                        <td>Appear in general results</td>
                        <td>Appear in general results</td>
                      </tr>
                      <tr>
                        <th scope="row">Candidate Database Unlocks</th>
                        <td>Appear in general results</td>
                        <td>Appear in general results</td>
                        <td>Appear in general results</td>
                        <td>Appear in general results</td>
                      </tr>
                      <tr>
                        <th scope="row">Live jobs at a time</th>
                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                      </tr>
                      <tr>
                        <th scope="row">Number of job Boosting</th>
                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                        <td><i class="fa fa-check" aria-hidden="true"></i></td>
                      </tr>
                      <tr>
                        <th scope="row">Priority customer support</th>
                        <td>Appear in general results</td>
                        <td>Appear in general results</td>
                        <td>Appear in general results</td>
                        <td>Appear in general results</td>
                      </tr>
                    </tbody>
                  </table>
                <h4 style="text-align: center; margin-top: 20px;">Show More Features</h4>
                <a style="text-align: center;" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
                aria-controls="collapseExample"><i class="fa fa-chevron-down" aria-hidden="true" style="color: #031a35;"></i></a>
                <br><br><br>
            </div>
        </div>
    </section>
</main>
<!-- footer -->
<app-footer></app-footer>