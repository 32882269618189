import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormsModule} from '@angular/forms';

@Component({
  selector: 'app-builder-form',
  templateUrl: './builder-form.component.html',
  styleUrls: ['./builder-form.component.css']
})
export class BuilderFormComponent implements OnInit {

  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  isEditable = false;
  
  keyword = 'name';
  
  data = [{}];

  public skills = [
    {
      id: 1,
      name: 'Java',
    },
    {
      id: 2,
      name: 'PHP',
    },
    {
      id: 3,
      name: 'Node',
    },
    {
      id: 4,
      name: 'HTML',
    },
    {
      id: 5,
      name: 'CSS',
    },
    {
      id: 6,
      name: 'Angular CLI',
    },
    {
      id: 7,
      name: 'React',
    },
    {
      id: 8,
      name: 'jQuery',
    },
    {
      id: 9,
      name: 'AJAX',
    },
    {
      id: 10,
      name: 'JavaScript',
    },
    {
      id: 11,
      name: 'Laravel',
    },
    {
      id: 12,
      name: 'Python',
    }
  ];

  selectEvent(item: any) {
      this.data.push(item);
    console.log(this.data);
  }

  remove(i: any) {
    const index = this.data.indexOf(i, 0);
    if (index > -1) {
      this.data.splice(index, 1);
    }
  }

  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(item: any) {
  }

  constructor(private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    console.log(this.data);
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }
}
