<!-- navigation bar -->
<app-navbar></app-navbar>
<main>
    <section>
        <div class="container-fluid" style="background-image: url(/assets/img/testibg.jpg); padding: 50px; background-repeat: no-repeat; background-size: cover;">
            <div class="row header-section">
                <h2 class="heading">Referral</h2>
                <br>
                <h4 class="sub-heading">Home > Referral</h4>
            </div>
        </div>
        <div class="container" style="margin-top: 80px;">
            <ol class="breadcrumb breadcrumb-arrow">
                <li><a [routerLink]="['/home']">Home</a></li>
                <li class="active"><span>Referral</span></li>
            </ol>
        </div>
    </section>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-5 mx-auto p-3 m-5">
                    <img src="/assets/img/referral.PNG" alt="" class="img-fluid">
                </div>
                <!-- <div class="col"></div> -->
                <div class="col-5 mx-auto shadow bg-white rounded p-3 m-5">
                    <h3>Reward Your Friends & Yourself</h3>
                    <p style="margin-right: 100px;">make a special specimen book. It has survived not only five centuries, but also the leap into electronic typesetting remaining essentially</p>
                    <div class="input-group mx-auto" style="width: 400px;">
                        <input style="border-radius: 10px; margin-right: 5px;" type="text" class="form-control" placeholder="Enter your friend's email id" aria-label="Recipient's username" aria-describedby="basic-addon2">
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2" style="border-radius: 10px; background-color: #171C2F; color: #fff; height: 45px; width: 100px; font-size: large; text-align: center; display: block;">Invite</span>
                        </div>
                    </div>
                    <div class="input-group mx-auto" style="width: 400px; margin-top: 10px;">
                        <input style="border-radius: 10px; margin-right: 5px;" type="text" class="form-control" placeholder="Autogenrated link" aria-label="Recipient's username" aria-describedby="basic-addon2">
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2" style="border-radius: 10px; background-color: #9d9d9d; color: #fff; height: 45px; width: 100px; font-size: large; text-align: center; display: block;">Copy</span>
                        </div>
                    </div>
                    <br>
                    <h3>Share your links</h3>
                    <div class="form-row">
                        <div class="m-1"><button style="background-color: #4368AC;" class="btn cm-btn">Facebook</button></div>
                        <div class="m-1"><button style="background-color: #DB3E35;" class="btn cm-btn">Google</button></div>
                        <div class="m-1"><button style="background-color: #32CCFF;" class="btn cm-btn">Twitter</button></div>
                        <div class="m-1"><button style="background-color: #1CD03D;" class="btn cm-btn">WhatsApp</button></div>
                    </div>
                    <br>
                    <p>How it works || T & C || My Referrals</p>
                </div>
            </div>
        </div>
    </section>
</main>
<!-- footer -->
<app-footer></app-footer>