<!-- navigation bar -->
<app-navbar></app-navbar>
<main>
<!-- form -->
<section style="margin-top: 140px;">
<div class="container">
    <ol class="breadcrumb breadcrumb-arrow">
        <li><a [routerLink]="['/home']">Home</a></li>
        <li><a [routerLink]="['/dashboard']">Dashboard</a></li>
        <li class="active"><span>Edit Profile</span></li>
    </ol>
    <div class="row rounded bg-white mt-5 mb-5">
        <div class="col-md-3 border-right">
            <div class="d-flex flex-column align-items-center text-center p-3 py-5"><img class="rounded-circle mt-5" width="150px" src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"><span class="font-weight-bold">Joy Ghosh</span><span class="text-black-50">joy@gmail.com</span><span> </span></div>
        </div>
        <div class="col-md-5 border-right">
            <div class="p-3 py-5">
                <div class="d-flex justify-content-between align-items-center mb-3">
                    <h4 class="text-right">Profile Settings</h4>
                </div>
                <div class="row mt-2">
                    <div class="col-md-6"><label class="labels">Name</label><input type="text" class="form-control" placeholder="first name" value=""></div>
                    <div class="col-md-6"><label class="labels">Surname</label><input type="text" class="form-control" value="" placeholder="surname"></div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-12"><label class="labels">Mobile Number</label><input type="text" class="form-control" placeholder="enter phone number" value=""></div>
                    <div class="col-md-12"><label class="labels">Address</label><input type="text" class="form-control" placeholder="enter address" value=""></div>
                    <div class="col-md-12"><label class="labels">Experience</label><input type="text" class="form-control" placeholder="Expert" value=""></div>
                    <div class="col-md-12"><label class="labels">Hourly Rate</label><input type="text" class="form-control" placeholder="200" value=""></div>
                    <div class="col-md-12"><label class="labels">English Level</label><input type="text" class="form-control" placeholder="Expert" value=""></div>
                    <div class="col-md-12"><label class="labels">Availability</label><input type="text" class="form-control" placeholder="6 months" value=""></div>
                    <div class="col-md-12"><label class="labels">Email ID</label><input type="text" class="form-control" placeholder="enter email id" value=""></div>
                    <div class="col-md-12"><label class="labels">Your Bio</label><input type="text" class="form-control" placeholder="Bio" value=""></div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6"><label class="labels">Nationality</label><input type="text" class="form-control" placeholder="indian" value=""></div>
                    <div class="col-md-6"><label class="labels">State/Region</label><input type="text" class="form-control" value="" placeholder="state"></div>
                </div>
                <div class="mt-5 text-center"><button class="btn btn-primary profile-button" type="button">Save Profile</button></div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="p-3 py-5">
                <div class="d-flex justify-content-between align-items-center experience"><span>Edit Experience</span><span class="border px-3 p-1 add-experience"><i class="fa fa-plus"></i>&nbsp;Experience</span></div><br>
                <div class="col-md-12"><label class="labels">Experience in Designing</label><input type="text" class="form-control" placeholder="experience" value=""></div> <br>
                <div class="col-md-12"><label class="labels">Additional Details</label><input type="text" class="form-control" placeholder="additional details" value=""></div>
            </div>
            <div class="p-3 py-5">
                <div class="d-flex justify-content-between align-items-center experience"><span>Work Links</span><span class="border px-3 p-1 add-experience"><i class="fa fa-plus"></i>&nbsp;Links</span></div><br>
                <div class="col-md-12"><label class="labels">Work Links</label><input type="text" class="form-control" placeholder="website links" value=""></div>
            </div>
            <div class="p-3 py-5">
                <div class="d-flex justify-content-between align-items-center experience"><span>Skills</span></div><br>
                <label class="labels">Add skills</label>
                <div class="ng-autocomplete">
                    <ng-autocomplete
                      [data]="skills"
                      [searchKeyword]="keyword"
                      placeholder="Enter the Skills"
                      (selected)='selectEvent($event)'
                      (inputChanged)='onChangeSearch($event)'
                      (inputFocused)='onFocused($event)'
                      historyIdentifier="skills"
                      [itemTemplate]="itemTemplate"
                      [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>
                  
                    <ng-template #itemTemplate let-item>
                      <a [innerHTML]="item.name"></a>
                    </ng-template>
                  
                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                  </div>
            </div>
        </div>
    </div>
</div>
</section>

</main>
<!-- footer -->
<app-footer></app-footer>