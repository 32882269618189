import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.css']
})
export class JobComponent implements OnInit {
  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }

  public isCollapsed1 = false;
  public isCollapsed2 = false;
  public isCollapsed3 = false;
  public isCollapsed4 = false;
  public isCollapsed5 = false;
  public isCollapsed6 = false;

  keyword = 'name';
  
  data = [{}];

  public jobcategory = [
    {
      id: 1,
      name: 'Home Care Services',
    },
    {
      id: 2,
      name: 'It Support',
    },
    {
      id: 3,
      name: 'Legal',
    },
    {
      id: 4,
      name: 'Finance/ Accounts',
    },
    {
      id: 5,
      name: 'Sales/ Admin Support',
    },
    {
      id: 6,
      name: 'Man Power',
    }
  ];
  public jobtitle = [
    {
      id: 1,
      name: 'Developer',
    },
    {
      id: 2,
      name: 'Hr',
    },
    {
      id: 3,
      name: 'Manager',
    },
    {
      id: 4,
      name: 'Accountant',
    },
    {
      id: 5,
      name: 'Banker',
    },
    {
      id: 6,
      name: 'Clark',
    }
  ];
  public joblocation = [
    {
      id: 1,
      name: 'Kolkata',
    },
    {
      id: 2,
      name: 'Bangalore',
    },
    {
      id: 3,
      name: 'Hyderabad',
    },
    {
      id: 4,
      name: 'Delhi',
    },
    {
      id: 5,
      name: 'Mumbai',
    },
    {
      id: 6,
      name: 'Assam',
    }
  ];

  selectEvent(item: any) {
      this.data.push(item);
    console.log(this.data);
  }

  remove(i: any) {
    const index = this.data.indexOf(i, 0);
    if (index > -1) {
      this.data.splice(index, 1);
    }
  }

  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(item: any) {
  }
  constructor() { }

  ngOnInit(): void {
  }

}
