<!-- navigation bar -->
<app-navbar></app-navbar>
<main>
    <section>
        <div class="container-fluid" style="background: url(/assets/img/banner.jpg); height: max-content; background-repeat: no-repeat; background-size: cover;">
            <div class="row" style="min-height: 150px;">
                <div class="mx-auto custom-control" style="margin-top: 50px;">
                    <div class="form-box mx-auto">
                        <div class="input-group">
                            <input type="text" class="search-field" aria-label="Category" placeholder=" Search Category">
                            <div class="input-group-append"><button class="search-btn" type="button"><i class="fa fa-search" aria-hidden="true"></i> FIND</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="container">
            <div class="row" style="margin-top: 20px;">
                <h3 style="text-align: center; margin-bottom: 30px;">Subcategory Listing</h3>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-1s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Home Care Services</h4>
                            <div class="form-row">
                                <p style="font-size: 14px;"><span style="color: #6bdc43; margin-left: 5px;">☆</span>&nbsp;<span style="font-weight: 550;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large; position: relative; top: 7px; color: #fff;">4</span></p>
                                <p class="col" style="font-weight: 500;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-2s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Finance / Account</h4>
                            <div class="form-row">
                                <p style="font-size: 14px;"><span style="color: #6bdc43; margin-left: 5px;">☆</span>&nbsp;<span style="font-weight: 550;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large; position: relative; top: 7px; color: #fff;">22</span></p>
                                <p class="col" style="font-weight: 500;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-3s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Legal</h4>
                            <div class="form-row">
                                <p style="font-size: 14px;"><span style="color: #6bdc43; margin-left: 5px;">☆</span>&nbsp;<span style="font-weight: 550;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large; position: relative; top: 7px; color: #fff;">9</span></p>
                                <p class="col" style="font-weight: 500;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-4s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Tutor / Trainer</h4>
                            <div class="form-row">
                                <p style="font-size: 14px;"><span style="color: #6bdc43; margin-left: 5px;">☆</span>&nbsp;<span style="font-weight: 550;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large; position: relative; top: 7px; color: #fff;">11</span></p>
                                <p class="col" style="font-weight: 500;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 20px;">
                <div class="col-3 animate__animated animate__bounceIn animate__delay-1s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">It Solution</h4>
                            <div class="form-row">
                                <p style="font-size: 14px;"><span style="color: #6bdc43; margin-left: 5px;">☆</span>&nbsp;<span style="font-weight: 550;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large; position: relative; top: 7px; color: #fff;">4</span></p>
                                <p class="col" style="font-weight: 500;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-2s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Sales / Admin Support</h4>
                            <div class="form-row">
                                <p style="font-size: 14px;"><span style="color: #6bdc43; margin-left: 5px;">☆</span>&nbsp;<span style="font-weight: 550;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large; position: relative; top: 7px; color: #fff;">22</span></p>
                                <p class="col" style="font-weight: 500;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-3s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Man Power</h4>
                            <div class="form-row">
                                <p style="font-size: 14px;"><span style="color: #6bdc43; margin-left: 5px;">☆</span>&nbsp;<span style="font-weight: 550;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large; position: relative; top: 7px; color: #fff;">9</span></p>
                                <p class="col" style="font-weight: 500;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-4s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Civil Work / Designing</h4>
                            <div class="form-row">
                                <p style="font-size: 14px;"><span style="color: #6bdc43; margin-left: 5px;">☆</span>&nbsp;<span style="font-weight: 550;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large; position: relative; top: 7px; color: #fff;">11</span></p>
                                <p class="col" style="font-weight: 500;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 20px; margin-bottom: 20px;">
                <div class="col-3 animate__animated animate__bounceIn animate__delay-1s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Medical</h4>
                            <div class="form-row">
                                <p style="font-size: 14px;"><span style="color: #6bdc43; margin-left: 5px;">☆</span>&nbsp;<span style="font-weight: 550;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large; position: relative; top: 7px; color: #fff;">4</span></p>
                                <p class="col" style="font-weight: 500;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-2s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Wedding Services</h4>
                            <div class="form-row">
                                <p style="font-size: 14px;"><span style="color: #6bdc43; margin-left: 5px;">☆</span>&nbsp;<span style="font-weight: 550;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large; position: relative; top: 7px; color: #fff;">22</span></p>
                                <p class="col" style="font-weight: 500;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-3s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Astrology</h4>
                            <div class="form-row">
                                <p style="font-size: 14px;"><span style="color: #6bdc43; margin-left: 5px;">☆</span>&nbsp;<span style="font-weight: 550;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large; position: relative; top: 7px; color: #fff;">9</span></p>
                                <p class="col" style="font-weight: 500;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 animate__animated animate__bounceIn animate__delay-4s">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Tours & Travels</h4>
                            <div class="form-row">
                                <p style="font-size: 14px;"><span style="color: #6bdc43; margin-left: 5px;">☆</span>&nbsp;<span style="font-weight: 550;">4.8/5</span> average rating</p>
                            </div>
                            <div class="form-row">
                                <p href="#" class="col-2 circle-div"><span style="font-size: large; position: relative; top: 7px; color: #fff;">11</span></p>
                                <p class="col" style="font-weight: 500;">People</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="row">
            <h4 style="text-align: center; margin-top: 20px;">Show More Features</h4>
            <a style="text-align: center;" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample"><i class="fa fa-chevron-down" aria-hidden="true" style="color: #031a35;"></i></a>
        </div>
        <br>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
            <div class="container">
                <div class="row" style="margin-bottom: 20px;">
                    <div class="col-3 mx-auto shadow p-2 mb-3" style="height: max-content;">
                        <br>
                        <h3>Category</h3>
                        <hr>
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                <label class="form-check-label" for="flexCheckChecked">
                                    Home Care Services
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Finance / Account
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Legal
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Tutor / Trainer
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    It Solution
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Sales / Admin Support
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Man Power
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Civil Work / Designing
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Medical
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Wedding Services
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Astrology
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Tours & Travel
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row mx-auto shadow p-2 mb-3">
                            <div style="position: relative; bottom: -15px;">
                                <button class="btn custom-btn custom-btn-green" style="float: right; margin: 5px;">Post A Project</button>
                                <button class="btn custom-btn custom-btn-blue" style="float: right; margin: 5px;">Sign Up For Work</button>
                            </div>
                            <div class="row">
                                <h4>A</h4>
                                <hr style="width: 97%;">
                                <div class="col-md-4">
                                    <ul>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aheets containing</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-4">
                                    <ul>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aetraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aetraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aetraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aetraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aetraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aetraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aetraset sheets containing</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-4">
                                    <ul>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aetraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aetraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aetraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aetraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aetraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aetraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Aetraset sheets containing</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row">
                                <h4>B</h4>
                                <hr style="width: 97%;">
                                <div class="col-md-4">
                                    <ul>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Bheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Bheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Bheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Bheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Bheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Bheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Bheets containing</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-4">
                                    <ul>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Betraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Betraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Betraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Betraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Betraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Betraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Betraset sheets containing</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-4">
                                    <ul>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Betraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Betraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Betraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Betraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Betraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Betraset sheets containing</a>
                                        </li>
                                        <br>
                                        <li>
                                            <a><i class="fa fa-chevron-right" aria-hidden="true" style="color: #72C457; font-weight: 500; font-size: 12px;"></i> Betraset sheets containing</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </section>
</main>
<!-- footer -->
<app-footer></app-footer>