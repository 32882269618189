<!-- navigation bar -->
<app-navbar></app-navbar>
<main>
<!-- carousel -->
<section>
    <div class="container-fluid" style="background: url(/assets/img/banner.jpg); height: max-content; background-repeat: no-repeat; background-size: cover;">
        <div class="row" style="min-height: 650px;">
                <div class="col mx-auto" style="margin-top: 240px;">
                    <h2 style="font-size: 50px; color: #fff;" class="font-weight-bold text-center">Find Your <span style="color: #6eda49;">Desired</span> Job</h2>
                    <h3 style="font-size: 20px; color: #fff;" class="text-center font-weight-light">Jobs, Employment & Future Career Opportunities</h3><br><br>
                    
                    <div class="col form-box mx-auto">
                        <div class="col input-group">
                            <input type="text" class="search-field" aria-label="Category" placeholder="Job Category">
                            <div class="input-group-append">
                                <span class="input-group-text text-box"><i class="fa fa-chevron-down" aria-hidden="true"></i></span>
                            </div>
                            <input type="text" class="search-field" aria-label="Title" placeholder="Job Title">
                            <div class="input-group-append">
                                <span class="input-group-text text-box"><i class="fa fa-search" aria-hidden="true"></i></span>
                            </div>
                            <input type="text" class="search-field" aria-label="Location" placeholder="City or State">
                            <div class="input-group-append">
                                <span class="input-group-text text-box"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                            </div>
                            <div class="input-group-append"><button class="search-btn" type="button">SEARCH</button></div>
                        </div>
                    </div>
                    <br><br>
                    <div class="col text-center">
                        <button class="submit-btn" type="button">Looking for a Job</button>
                        <button class="submit-btn" type="button">Looking for an Employee</button>
                    </div>
                </div>
        </div>
    </div>
</section>
<br><br><br>
<!-- How it works -->
<section>
    <div class="container">
        <div class="row">
            <div class="text-center">
                <p>WORKING PROCESS</p>
                <h2>How It <span class="" style="color:green;">Works</span></h2>
            </div>
            <div class="col-md-4 text-center animate__animated animate__slideInLeft animate__delay-1s">
                <img src="/assets/img/1.png" class="img-responsive">
                <h3>Create An Account</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </div>
            <div class="col-md-4 text-center animate__animated animate__slideInUp animate__delay-1s">
                <img src="/assets/img/2.png" class="img-responsive">
                <h3>Search Jobs</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>        
            </div>
            <div class="col-md-4 text-center animate__animated animate__slideInRight animate__delay-1s">
                <img src="/assets/img/3.png" class="img-responsive">
                <h3>Save & Apply</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>    
            </div>
        </div>
    </div>
</section>
<br><br><br>

<section>
    <div class="container-fuid">
        <div class="row">
            <div class="col-md-6" style="background: #171C2F; padding: 100px 50px;">
                <div class="text-center">
                    <h2 style="color: white;">Make a Difference with Your <br> Online Resume!</h2>
                    <p style="color: white;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam neque perferendis aperiam laborum consequuntur! Nihil quidem eius eum magnam autem deserunt dignissimos.</p><br>
                    <button class="submit-btn" type="button">Find Buddy</button>
                </div>
            </div>
            <div class="col-md-6" style="background: #6eda49; padding: 100px 50px;">
                <div class="text-center">
                    <h2 style="color: white;">Make the most of growth <br> Opportunities</h2>
                    <p style="color: white;">Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam neque perferendis aperiam laborum consequuntur! Nihil quidem eius eum magnam autem deserunt dignissimos.</p><br>
                    <button class="submit1-btn" type="button">Register for Work</button>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Explore By Category -->
<section class="container-fluid" style="background: #faf3f2; padding: 60px;">
    <div class="container">
        <div style="display: block; text-align: center;">
            <h1>Explore By Category</h1>
            <h4>Leverage agile frameworks to provide a robust synopsis for <br>
                high level overviews to start.</h4>
            <br>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front" style="background-color: #7DC7C6;">
                            <div class="text-center">
                                <br>
                                <img src="/assets/img/c1.jpg" alt="..." height="100" width="100">
                            </div>
                            <div class="card-body text-center">
                              <h3 class="card-text">Home Care Service</h3>
                              <p class="card-text">300+ Jobs</p>
                            </div>
                        </div>
                        <div class="flip-card-back">
                            <div class="text-center" style="margin-top: 8px;">
                                <h3 style="color: #fff;">Home Care Service</h3>
                                <hr style="color: #fff; margin-left: 33%; height: 2px;" width="90">
                            </div>
                            <div class="card-body text-center">
                                <p class="card-text" style="color: #fff;">This is a basic card with image on top, title, description and button.</p>
                                <button class="card-btn" type="button">Explore</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front" style="background-color: #E6F2FE;">
                            <div class="text-center">
                                <br>
                                <img src="/assets/img/4.png" alt="..." height="100" width="100">
                            </div>
                            <div class="card-body text-center">
                              <h3 class="card-text">Finance/ Accounts</h3>
                              <p class="card-text">300+ Jobs</p>
                            </div>
                        </div>
                        <div class="flip-card-back">
                            <div class="text-center" style="margin-top: 8px;">
                                <h3 style="color: #fff;">Finance/ Accounts</h3>
                                <hr style="color: #fff; margin-left: 33%; height: 2px;" width="90">
                            </div>
                            <div class="card-body text-center">
                                <p class="card-text" style="color: #fff;">This is a basic card with image on top, title, description and button.</p>
                                <button class="card-btn" type="button">Explore</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front" style="background-color: #FBEAD3;">
                            <div class="text-center">
                                <br>
                                <img src="/assets/img/5.png" alt="..." height="100" width="100">
                            </div>
                            <div class="card-body text-center">
                              <h3 class="card-text">Legal</h3>
                              <p class="card-text">300+ Jobs</p>
                            </div>
                        </div>
                        <div class="flip-card-back">
                            <div class="text-center" style="margin-top: 8px;">
                                <h3 style="color: #fff;">Legal</h3>
                                <hr style="color: #fff; margin-left: 33%; height: 2px;" width="90">
                            </div>
                            <div class="card-body text-center">
                                <p class="card-text" style="color: #fff;">This is a basic card with image on top, title, description and button.</p>
                                <button class="card-btn" type="button">Explore</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front" style="background-color: #F1A5C7;">
                            <div class="text-center">
                                <br>
                                <img src="/assets/img/6.png" alt="..." height="100" width="100">
                            </div>
                            <div class="card-body text-center">
                              <h3 class="card-text">Tutor/Trainer</h3>
                              <p class="card-text">300+ Jobs</p>
                            </div>
                        </div>
                        <div class="flip-card-back">
                            <div class="text-center" style="margin-top: 8px;">
                                <h3 style="color: #fff;">Tutor/Trainer</h3>
                                <hr style="color: #fff; margin-left: 33%; height: 2px;" width="90">
                            </div>
                            <div class="card-body text-center">
                                <p class="card-text" style="color: #fff;">This is a basic card with image on top, title, description and button.</p>
                                <button class="card-btn" type="button">Explore</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><br>
        <div class="row">
            <div class="col-md-3">
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front" style="background-color: #FFD39F;">
                            <div class="text-center">
                                <br>
                                <img src="/assets/img/7.png" alt="..." height="100" width="100">
                            </div>
                            <div class="card-body text-center">
                              <h3 class="card-text">It Solution</h3>
                              <p class="card-text">300+ Jobs</p>
                            </div>
                        </div>
                        <div class="flip-card-back">
                            <div class="text-center" style="margin-top: 8px;">
                                <h3 style="color: #fff;">It Solution</h3>
                                <hr style="color: #fff; margin-left: 33%; height: 2px;" width="90">
                            </div>
                            <div class="card-body text-center">
                                <p class="card-text" style="color: #fff;">This is a basic card with image on top, title, description and button.</p>
                                <button class="card-btn" type="button">Explore</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front" style="background-color: #99D0EF;">
                            <div class="text-center">
                                <br>
                                <img src="/assets/img/8.png" alt="..." height="100" width="100">
                            </div>
                            <div class="card-body text-center">
                              <h3 class="card-text">Sales/Admin Support</h3>
                              <p class="card-text">300+ Jobs</p>
                            </div>
                        </div>
                        <div class="flip-card-back">
                            <div class="text-center" style="margin-top: 8px;">
                                <h3 style="color: #fff;">Sales/Admin Support</h3>
                                <hr style="color: #fff; margin-left: 33%; height: 2px;" width="90">
                            </div>
                            <div class="card-body text-center">
                                <p class="card-text" style="color: #fff;">This is a basic card with image on top, title, description and button.</p>
                                <button class="card-btn" type="button">Explore</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front" style="background-color: #74DFD1;">
                            <div class="text-center">
                                <br>
                                <img src="/assets/img/9.png" alt="..." height="100" width="100">
                            </div>
                            <div class="card-body text-center">
                              <h3 class="card-text">Man Power</h3>
                              <p class="card-text">300+ Jobs</p>
                            </div>
                        </div>
                        <div class="flip-card-back">
                            <div class="text-center" style="margin-top: 8px;">
                                <h3 style="color: #fff;">Man Power</h3>
                                <hr style="color: #fff; margin-left: 33%; height: 2px;" width="90">
                            </div>
                            <div class="card-body text-center">
                                <p class="card-text" style="color: #fff;">This is a basic card with image on top, title, description and button.</p>
                                <button class="card-btn" type="button">Explore</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front" style="background-color: #85E3E3;">
                            <div class="text-center">
                                <br>
                                <img src="/assets/img/10.png" alt="..." height="100" width="100">
                            </div>
                            <div class="card-body text-center">
                              <h3 class="card-text">Civil works/Designing</h3>
                              <p class="card-text">300+ Jobs</p>
                            </div>
                        </div>
                        <div class="flip-card-back">
                            <div class="text-center" style="margin-top: 8px;">
                                <h3 style="color: #fff;">Civil works/Designing</h3>
                                <hr style="color: #fff; margin-left: 33%; height: 2px;" width="90">
                            </div>
                            <div class="card-body text-center">
                                <p class="card-text" style="color: #fff;">This is a basic card with image on top, title, description and button.</p>
                                <button class="card-btn" type="button">Explore</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><br>
        <div class="row">
            <div class="col-md-3">
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front" style="background-color: #F9AD9D;">
                            <div class="text-center">
                                <br>
                                <img src="/assets/img/11.png" alt="..." height="100" width="100">
                            </div>
                            <div class="card-body text-center">
                              <h3 class="card-text">Medical</h3>
                              <p class="card-text">300+ Jobs</p>
                            </div>
                        </div>
                        <div class="flip-card-back">
                            <div class="text-center" style="margin-top: 8px;">
                                <h3 style="color: #fff;">Medical</h3>
                                <hr style="color: #fff; margin-left: 33%; height: 2px;" width="90">
                            </div>
                            <div class="card-body text-center">
                                <p class="card-text" style="color: #fff;">This is a basic card with image on top, title, description and button.</p>
                                <button class="card-btn" type="button">Explore</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front" style="background-color: #F88DA9;">
                            <div class="text-center">
                                <br>
                                <img src="/assets/img/12.png" alt="..." height="100" width="100">
                            </div>
                            <div class="card-body text-center">
                              <h3 class="card-text">Wedding Service</h3>
                              <p class="card-text">300+ Jobs</p>
                            </div>
                        </div>
                        <div class="flip-card-back">
                            <div class="text-center" style="margin-top: 8px;">
                                <h3 style="color: #fff;">Wedding Service</h3>
                                <hr style="color: #fff; margin-left: 33%; height: 2px;" width="90">
                            </div>
                            <div class="card-body text-center">
                                <p class="card-text" style="color: #fff;">This is a basic card with image on top, title, description and button.</p>
                                <button class="card-btn" type="button">Explore</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front" style="background-color: #F7DE7A;">
                            <div class="text-center">
                                <br>
                                <img src="/assets/img/13.png" alt="..." height="100" width="100">
                            </div>
                            <div class="card-body text-center">
                              <h3 class="card-text">Astrology</h3>
                              <p class="card-text">300+ Jobs</p>
                            </div>
                        </div>
                        <div class="flip-card-back">
                            <div class="text-center" style="margin-top: 8px;">
                                <h3 style="color: #fff;">Astrology</h3>
                                <hr style="color: #fff; margin-left: 33%; height: 2px;" width="90">
                            </div>
                            <div class="card-body text-center">
                                <p class="card-text" style="color: #fff;">This is a basic card with image on top, title, description and button.</p>
                                <button class="card-btn" type="button">Explore</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front" style="background-color: #FEACB1;">
                            <div class="text-center">
                                <br>
                                <img src="/assets/img/14.png" alt="..." height="100" width="100">
                            </div>
                            <div class="card-body text-center">
                              <h3 class="card-text">Tours & Travels</h3>
                              <p class="card-text">300+ Jobs</p>
                            </div>
                        </div>
                        <div class="flip-card-back">
                            <div class="text-center" style="margin-top: 8px;">
                                <h3 style="color: #fff;">Tours & Travels</h3>
                                <hr style="color: #fff; margin-left: 33%; height: 2px;" width="90">
                            </div>
                            <div class="card-body text-center">
                                <p class="card-text" style="color: #fff;">This is a basic card with image on top, title, description and button.</p>
                                <button class="card-btn" type="button">Explore</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <button class="more-btn mx-auto custom-control">View More</button>
    </div>
</section>
<br><br><br>
<!-- Why Choose Us -->
<section>
    <div class="container">
        <div style="display: block; text-align: center;">
            <h1>Why Choose Us</h1>
            <br>
            <h4>Leverage agile frameworks to provide a robust synopsis for <br>
                high level overviews to start..</h4>
            <br>
        </div>
        <div class="row">
            <div class="col-md-4">
                <ul class="list-unstyled">
                    <li class="media mb-5">
                        <div class="media-body">
                            <div>
                                <img src="/assets/img/w1.jpg" alt="...">
                                <div>
                                  <h3>Lorem ipsum dolor sit amet</h3>
                                  <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quo natus exercitationem incidunt sequi totam tempore quia, </p>
                                </div>
                            </div>
                        </div>

                </li></ul>
                <ul class="list-unstyled">
                    <li class="media mb-5">
                        <div class="media-body">
                            <div>
                                <img src="/assets/img/w1.jpg" alt="...">
                                <div>
                                  <h3>Lorem ipsum dolor sit amet</h3>
                                  <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quo natus exercitationem incidunt sequi totam tempore quia, </p>
                                </div>
                            </div>
                        </div>

                </li></ul>
            </div>
            <div class="col-md-4">
                <img src="/assets/img/why-img.png" class="img-fluid" alt="">
            </div>
            <div class="col-md-4">
                <ul class="list-unstyled">
                    <li class="media mb-5">
                        <div class="media-body">
                            <div>
                                <img src="/assets/img/w1.jpg" alt="...">
                                <div>
                                  <h3>Lorem ipsum dolor sit amet</h3>
                                  <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quo natus exercitationem incidunt sequi totam tempore quia, </p>
                                </div>
                            </div>
                        </div>

                </li></ul>
                <ul class="list-unstyled">
                    <li class="media mb-5">
                        <div class="media-body">
                            <div>
                                <img src="/assets/img/w1.jpg" alt="...">
                                <div>
                                  <h3>Lorem ipsum dolor sit amet</h3>
                                  <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quo natus exercitationem incidunt sequi totam tempore quia, </p>
                                </div>
                            </div>
                        </div>

                </li></ul>
            </div>
        </div>
    </div>
</section>
<br><br><br>
<!-- Popular Jobs -->
<section class="container-fluid" style="background: #dadeed; padding: 60px;">
    <div class="container">
        <div style="display: block; text-align: center;">
            <h1>Popular Jobs</h1>
            <br>
            <h4>Leverage agile frameworks to provide a robust synopsis for <br>
                high level overviews to start.</h4>
            <br>
        </div>
        <div class="row">
            <div class="col-md-3 animate__animated animate__bounce animate__delay-1s">
                <div class="card">
                    <img src="/assets/img/p1.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                      <h3>Home Care Services</h3>
                      <p>More Than 300+ Jobs</p>
                      <p style="color: #28a745;">Job Value - 3000 - 5000 INR</p>
                      <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
                    </div>
                </div>
            </div>
            <div class="col-md-3 animate__animated animate__bounce animate__delay-1s">
                <div class="card">
                    <img src="/assets/img/p2.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3>Wedding Service</h3>
                        <p>More Than 200+ Jobs</p>
                        <p style="color: #28a745;">Job Value - 8000 - 12,000 INR</p>
                      <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
                    </div>
                </div>
            </div>
            <div class="col-md-3 animate__animated animate__bounce animate__delay-1s">
                <div class="card">
                    <img src="/assets/img/p3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3>Astrology Services</h3>
                        <p>More Than 350+ Jobs</p>
                        <p style="color: #28a745;">Job Value - 9000 - 10000 INR</p>
                      <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
                    </div>
                </div>
            </div>
            <div class="col-md-3 animate__animated animate__bounce animate__delay-1s">
                <div class="card">
                    <img src="/assets/img/p4.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3>It Solution</h3>
                        <p>More Than 900+ Jobs</p>
                        <p style="color: #28a745;">Job Value - 1500 - 1900 INR</p>
                      <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-3 animate__animated animate__bounce animate__delay-1s">
                <div class="card">
                    <img src="/assets/img/p5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3>Tours & Travels</h3>
                        <p>More Than 500+ Jobs</p>
                        <p style="color: #28a745;">Job Value - 4000 - 8000 INR</p>
                      <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
                    </div>
                </div>
            </div>
            <div class="col-md-3 animate__animated animate__bounce animate__delay-1s">
                <div class="card">
                    <img src="/assets/img/p6.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3>Finance/ Accounts</h3>
                        <p>More Than 400+ Jobs</p>
                        <p style="color: #28a745;">Job Value - 5000 - 8000 INR</p>
                      <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
                    </div>
                </div>
            </div>
            <div class="col-md-3 animate__animated animate__bounce animate__delay-1s">
                <div class="card">
                    <img src="/assets/img/p7.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3>Legal</h3>
                        <p>More Than 250+ Jobs</p>
                        <p style="color: #28a745;">Job Value - 7000 - 10000 INR</p>
                      <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
                    </div>
                </div>
            </div>
            <div class="col-md-3 animate__animated animate__bounce animate__delay-1s">
                <div class="card">
                    <img src="/assets/img/p8.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3>Admin Support</h3>
                        <p>More Than 400+ Jobs</p>
                        <p style="color: #28a745;">Job Value - 2000 - 2200 INR</p>
                      <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <br>
        <button class="more-btn mx-auto custom-control">View All Job</button> -->
    </div>
</section>
<!-- People's Experience -->
<section>
    <div class="container-fluid" class="100vh p90">
        <div class="parallax"style="min-height: 750px;">
            <div class="mx-auto custom-control text-center" style="margin-top: 100px;">
                <h1 style="color: #fff; font-size: 36px;">People's Experience</h1><br>
                <p style="color: #fff; font-size: 18px; font-weight: lighter;">Few words from candidates</p><br><br>
                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                    <!-- <ol class="carousel-indicators">
                      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    </ol> -->
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <div class="popover__content mx-auto custom-control">
                            <p class="popover__message">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et dapibus tortor. Suspendisse orci ante,<br> vestibulum at libero ac, vulputate vulputate sem. Cras quis lorem condimentum, ornare mi <br> molestie, ipsum.</p>
                        </div>
                        <img src="/assets/img/t.png" style="margin-top: -12px;">
                        <br>
                        <a>
                            <img src="/assets/img/tc1.png" class="m3">
                        </a>
                        <p class="text-success" style="font-weight: bold; font-size: medium;">Jenny Jo</p>
                        <p class="text-white">Designation</p>
                        <br>
                      </div>
                      <div class="carousel-item">
                        <div class="popover__content mx-auto custom-control">
                            <p class="popover__message">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et dapibus tortor. Suspendisse orci ante,<br> vestibulum at libero ac, vulputate vulputate sem. Cras quis lorem condimentum, ornare mi <br> molestie, ipsum.</p>
                        </div>
                        <img src="/assets/img/t.png" style="margin-top: -12px;">
                        <br>
                        <a>
                            <img src="/assets/img/tc1.png" class="m3">
                        </a>
                        <p class="text-success" style="font-weight: bold; font-size: medium;">Jenny Jo</p>
                        <p class="text-white">Designation</p>
                        <br>
                      </div>
                      <div class="carousel-item">
                        <div class="popover__content mx-auto custom-control">
                            <p class="popover__message">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et dapibus tortor. Suspendisse orci ante,<br> vestibulum at libero ac, vulputate vulputate sem. Cras quis lorem condimentum, ornare mi <br> molestie, ipsum.</p>
                        </div>
                        <img src="/assets/img/t.png" style="margin-top: -12px;">
                        <br>
                        <a>
                            <img src="/assets/img/tc1.png" class="m3">
                        </a>
                        <p class="text-success" style="font-weight: bold; font-size: medium;">Jenny Jo</p>
                        <p class="text-white">Designation</p>
                        <br>
                      </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </div>
            </div>
        </div>
    </div>
</section>
<br><br><br>
<!-- Freelancer's Experience -->
<section>
    <div class="container">
        <div style="display: block; text-align: center;">
            <h1>Freelancer's Experience</h1>
            <br>
            <h4>Leverage agile frameworks to provide a robust synopsis for <br>
                high level overviews to start..</h4>
            <br>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div style="display: flex;">
                    <h3 class="verticaltext">Alfred Gilbert</h3>
                    <img src="/assets/img/person1.jpg" class="card-img-top" alt="..." class="instructor-img">
                </div>
                <div class="text-center instructor-txt">
                    <br>
                    <h3>Senior Health and Food</h3>
                    <div class="row">
                        <div class="col-md-4">
                            <p class="text-left">5 Years</p>
                        </div>
                        <div class="col">
                            <div class="rating text-right">
                                <span>☆</span><span>☆</span><span>☆</span><span>☆</span><span>☆</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div style="display: flex;">
                    <h3 class="verticaltext">Samantha Riley</h3>
                    <img src="/assets/img/person2.jpg" class="card-img-top" alt="..." class="instructor-img">
                </div>
                <div class="text-center instructor-txt">
                    <br>
                    <h3>Online Tutor</h3>
                    <div class="row">
                        <div class="col-md-4">
                            <p class="text-left">3 Years</p>
                        </div>
                        <div class="col">
                            <div class="rating text-right">
                                <span>☆</span><span>☆</span><span>☆</span><span>☆</span><span>☆</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div style="display: flex;">
                    <h3 class="verticaltext">Samantha Riley</h3>
                    <img src="/assets/img/person3.jpg" class="card-img-top" alt="..." class="instructor-img">
                </div>
                <div class="text-center instructor-txt">
                    <br>
                    <h3>IT Solution</h3>
                    <div class="row">
                        <div class="col-md-4">
                            <p class="text-left">2 Years</p>
                        </div>
                        <div class="col">
                            <div class="rating text-right">
                                <span>☆</span><span>☆</span><span>☆</span><span>☆</span><span>☆</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div style="display: flex;">
                    <h3 class="verticaltext">Robert Martha</h3>
                    <img src="/assets/img/person4.jpg" class="card-img-top" alt="..." class="instructor-img">
                </div>
                <div class="text-center instructor-txt">
                    <br>
                    <h3>Admin Support</h3>
                    <div class="row">
                        <div class="col-md-4">
                            <p class="text-left">1 Years</p>
                        </div>
                        <div class="col">
                            <div class="rating text-right">
                                <span>☆</span><span>☆</span><span>☆</span><span>☆</span><span>☆</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<br><br><br>
<!-- Popular Cities -->
<section>
    <div class="container-fluid" style="background: #eff1ff; padding: 100px;">
        <div style="display: block; text-align: center; padding: 50px;">
            <h1>Popular Cities</h1>
            <br>
            <h4>Leverage agile frameworks to provide a robust synopsis for <br>
                high level overviews to start.</h4>
            <br>
        </div>
        <div class="">
            <!-- Flickity HTML init -->
            <div class="js-flickity" data-flickity='{ "wrapAround": true, "lazyLoad": true }'>
                <div class="carousel-cell">
                    <div class="col-md-1" style="margin-left: 10px;">
                        <img src="/assets/img/ct1.jpg" alt="" class="city-img">
                        <p class="text-center" style="margin-left: 18px;">Kolkata</p>
                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="col-md-1" style="margin-left: 10px;">
                        <img src="/assets/img/ct2.jpg" alt="" class="city-img">
                        <p class="text-center" style="margin-left: 8px;">Bangalore</p>
                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="col-md-1" style="margin-left: 10px;">
                        <img src="/assets/img/ct3.jpg" alt="" class="city-img">
                        <p class="text-center" style="margin-left: 15px;">Mumbai</p>
                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="col-md-1" style="margin-left: 10px;">
                        <img src="/assets/img/ct4.jpg" alt="" class="city-img">
                        <p class="text-center" style="margin-left: 26px;">Pune</p>
                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="col-md-1" style="margin-left: 10px;">
                        <img src="/assets/img/ct5.jpg" alt="" class="city-img">
                        <p class="text-center" style="margin-left: 26px;">Delhi</p>
                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="col-md-1" style="margin-left: 10px;">
                        <img src="/assets/img/ct6.jpg" alt="" class="city-img">
                        <p class="text-center" style="margin-left: 24px;">Noida</p>
                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="col-md-1" style="margin-left: 10px;">
                        <img src="/assets/img/ct7.jpg" alt="" class="city-img">
                        <p class="text-center" style="margin-left: 14px;">Gurgaon</p>
                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="col-md-1" style="margin-left: 10px;">
                        <img src="/assets/img/ct8.jpg" alt="" class="city-img">
                        <p class="text-center" style="margin-left: 10px;">Hydrabad</p>
                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="col-md-1" style="margin-left: 10px;">
                        <img src="/assets/img/ct9.jpg" alt="" class="city-img">
                        <p class="text-center" style="margin-left: 15px;">Chennai</p>
                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="col-md-1" style="margin-left: 10px;">
                        <img src="/assets/img/ct10.jpg" alt="" class="city-img">
                        <p class="text-center" style="margin-left: 2px;">Ahmedabad</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <section>
    <div class="container" style="padding: 50px;">
        <div class="row">
            <div style="display: block; text-align: center;">
                <h1>Latest Blog</h1>
                <br>
                <h4>Leverage agile frameworks to provide a robust synopsis for <br>
                    high level overviews to start.</h4>
                <br>
            </div>
            <div class="card-deck">
                <div class="card">
                  <img class="card-img-top" src="assets/img/p1.jpg" alt="Card image cap">
                  <div class="card-body">
                    <p class="card-text full-width-text"><small class="text-muted"><i class="fa fa-user" aria-hidden="true"></i> Henry H.Garrick <i class="fa fa-clock-o" aria-hidden="true"></i> 31 July 2018</small></p>
                    <hr>
                    <h3 class="card-title">How To Get Better Learning</h3>
                    <h4 class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar neque.</h4>
                    <button href="#" class="more-btn mx-auto custom-control" style="float: right;">Explore</button>
                  </div>
                </div>
                <div class="card">
                  <img class="card-img-top" src="assets/img/p2.jpg" alt="Card image cap">
                  <div class="card-body">
                    <p class="card-text full-width-text"><small class="text-muted"><i class="fa fa-user" aria-hidden="true"></i> Henry H.Garrick <i class="fa fa-clock-o" aria-hidden="true"></i> 31 July 2018</small></p>
                    <hr>
                    <h3 class="card-title">How To Get Better Learning</h3>
                    <h4 class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar neque.</h4>
                    <button href="#" class="more-btn mx-auto custom-control" style="float: right;">Explore</button>
                  </div>
                </div>
                <div class="card">
                  <img class="card-img-top" src="assets/img/p3.jpg" alt="Card image cap">
                  <div class="card-body">
                    <p class="card-text full-width-text"><small class="text-muted"><i class="fa fa-user" aria-hidden="true"></i> Henry H.Garrick <i class="fa fa-clock-o" aria-hidden="true"></i> 31 July 2018</small></p>
                    <hr>
                    <h3 class="card-title">How To Get Better Learning</h3>
                    <h4 class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar neque.</h4>
                    <button href="#" class="more-btn mx-auto custom-control" style="float: right;">Explore</button>
                  </div>
                </div>
              </div>
        </div>
    </div>
</section> -->

</main>
<!-- footer -->
<app-footer></app-footer>