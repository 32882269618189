import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { NavbarComponent } from './templates/navbar/navbar.component';
import { HomeComponent } from './pages/home/home.component';
import { FooterComponent } from './templates/footer/footer.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { JobComponent } from './pages/job/job.component';
import { LoginComponent } from './pages/login/login.component';
import { FindJobsComponent } from './pages/find-jobs/find-jobs.component';
import { DetailsComponent } from './pages/details/details.component';
import { OtpComponent } from './pages/otp/otp.component';
import { PagenotfoundComponent } from './templates/pagenotfound/pagenotfound.component';
import { BuilderFormComponent } from './templates/builder-form/builder-form.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './pages/profile/profile.component';
import { EditprofileComponent } from './pages/editprofile/editprofile.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { BlogDetailComponent } from './pages/blog-detail/blog-detail.component';
import { ContactComponent } from './pages/contact/contact.component';
import { SubcategoryListingComponent } from './pages/subcategory-listing/subcategory-listing.component';
import { PricingTableComponent } from './pages/pricing-table/pricing-table.component';
import { BenefitsComponent } from './pages/benefits/benefits.component';
import { ReferralComponent } from './pages/referral/referral.component';
import { JobPostListingComponent } from './pages/job-post-listing/job-post-listing.component';
import { EmployerDashboardComponent } from './pages/employer-dashboard/employer-dashboard.component';
import { EmployeeDashboardComponent } from './pages/employee-dashboard/employee-dashboard.component';
import { AgentDashboardComponent } from './pages/agent-dashboard/agent-dashboard.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    FooterComponent,
    RegistrationComponent,
    JobComponent,
    LoginComponent,
    FindJobsComponent,
    DetailsComponent,
    OtpComponent,
    PagenotfoundComponent,
    BuilderFormComponent,
    ProfileComponent,
    EditprofileComponent,
    ContactsComponent,
    DashboardComponent,
    BlogsComponent,
    BlogDetailComponent,
    ContactComponent,
    SubcategoryListingComponent,
    PricingTableComponent,
    BenefitsComponent,
    ReferralComponent,
    JobPostListingComponent,
    EmployerDashboardComponent,
    EmployeeDashboardComponent,
    AgentDashboardComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    AutocompleteLibModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },],
  bootstrap: [AppComponent]
})
export class AppModule { }
