<!-- navigation bar -->
<app-navbar></app-navbar>
<main>
    <section>
        <div class="container-fluid" style="background-image: url(/assets/img/banner.jpg); padding: 120px; background-repeat: no-repeat; background-size: cover;">
            <div class="row header-section">
                <h1 class="heading">Login</h1>
            </div>
            <div class="row">
                <div class="col-md-5 mx-auto shadow p-3 mb-5 bg-white rounded" style="height: max-content;">
                    <div class="myform form ">
                        <h2 style="text-decoration: none; color: #1d4354; font-weight: bold; letter-spacing: 0.1em;">Sign In</h2>
                    <form action="" method="post" name="login">
                        
                        <div class="form-group">
                            <input type="text" name="name"  class="form-control my-input" id="name" placeholder="Phone or email" required>
                        </div>
                
                        <div class="form-group">
                            <input type="password" min="0" name="password" id="password"  class="form-control my-input" placeholder="Password" required>
                        </div>
                        
                        <div class="form-row">
                            <div class="col-8">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                                    <label class="form-check-label" for="defaultCheck1" style="text-decoration: none; color: #1d4354; font-weight: bold;">
                                        Keep me signed in 
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <a [routerLink]="['/otp']" class="ps-hero__content__link text-right" style="text-decoration: none; color: #1d4354; font-weight: bold;"> Lost Your Password?</a>
                            </div>
                          </div>
                        <div class="form-group">
                            <a class="btn btn-block r-button" href="#">
                            Sign In
                            </a>
                        </div>
                        <p class="text-center">Don't Have an Account? <a [routerLink]="['/register']" class="ps-hero__content__link" style="color: green; font-weight: bold; text-decoration: none;">Create</a></p>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<!-- footer -->
<app-footer></app-footer>