<!-- navigation bar -->
<app-navbar></app-navbar>
<main>
    <section>
        <div class="container-fluid" style="background-image: url(/assets/img/testibg.jpg); padding: 50px; background-repeat: no-repeat; background-size: cover;">
            <div class="row header-section">
                <h2 class="heading">Contact Us.</h2>
                <br>
                <h4 class="sub-heading">Home > Contact</h4>
            </div>
        </div>
        <div class="container" style="margin-top: 100px;">
            <ol class="breadcrumb breadcrumb-arrow">
                <li><a [routerLink]="['/home']">Home</a></li>
                <li class="active"><span>Contact</span></li>
            </ol>
        </div>
    </section>
    <br>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-8">
                    <div class="row mx-auto shadow p-4 mb-5" style="border-radius: 5px;">
                        <h4>Leave A Message</h4>
                        <div class="form-row">
                            <div class="col">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text" id="basic-addon1"><i class="fa fa-user" aria-hidden="true" style="color: #6bdc43;"></i></span>
                                    </div>
                                    <input type="text" name="name"  class="form-control my-input" id="name" placeholder="Name *" required>
                                </div>    
                            </div>
                            <div class="col">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text" id="basic-addon2"><i class="fa fa-envelope" aria-hidden="true" style="color: #6bdc43;"></i></span>
                                    </div>
                                    <input type="email" name="email"  class="form-control my-input" id="email" placeholder="Email">
                                </div> 
                            </div>
                        </div>
                        <div class="form-row" style="margin-top: 25px;">
                            <div class="col">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text" id="basic-addon3"><i class="fa fa-phone" aria-hidden="true" style="color: #6bdc43;"></i></span>
                                    </div>
                                    <input type="text" name="phone"  class="form-control my-input" id="phone" placeholder="Phone">
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text" id="basic-addon4"><i class="fa fa-pencil-square-o" aria-hidden="true" style="color: #6bdc43;"></i></span>
                                    </div>
                                    <input type="text" name="subject"  class="form-control my-input" id="subject" placeholder="Subject">
                                </div>
                            </div>
                        </div>
                        <div class="form-row" style="margin-top: 25px;">
                            <div class="col">
                                <textarea rows="4" class="form-control" aria-label="..." placeholder="Type Your Message"></textarea>
                            </div>
                        </div>
                        <div class="form-group" style="margin-top: 25px;">
                            <a href="" class="btn" style="float: left; background: #171C2F; color: #fff; padding: 10px 25px;">SEND</a>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row mx-auto shadow p-4 mb-5 custom" style="border-radius: 5px;">
                        <h4 style="color: #fff; font-weight: 550;">Contact Info</h4>
                        <div>
                            <ul class="list-unstyled">
                                <li class="media ">
                                    <a class="img-fluid"><i class="fa fa-map" aria-hidden="true" style="color: #fff; font-size: 20px;"></i></a>
                                    <div class="media-body">
                                        <p style=" margin-left: 10px;">101, B Kalani Bag, Dewas #455001 MP India</p>
                                    </div>
                                </li>
                            </ul>
                            <hr style="color: #fff;">
                            <ul class="list-unstyled">
                                <li class="media ">
                                    <a class="img-fluid"><i class="fa fa-envelope" aria-hidden="true" style="color: #fff; font-size: 20px;"></i></a>
                                    <div class="media-body">
                                        <p style=" margin-left: 10px;">jobpro@exaple.com</p>
                                    </div>
                                </li>
                            </ul>
                            <hr style="color: #fff;">
                            <ul class="list-unstyled">
                                <li class="media ">
                                    <a class="img-fluid"><i class="fa fa-phone" aria-hidden="true" style="color: #fff; font-size: 20px;"></i></a>
                                    <div class="media-body">
                                        <p style=" margin-left: 10px;">+61 3 8376 6284</p>
                                    </div>
                                </li>
                            </ul>
                            <hr style="color: #fff;">
                            <ul class="list-unstyled">
                                <li class="media ">
                                    <div class="media-body">
                                        <div class="navbar">
                                            <div class="text-right">
                                                <button class="btn" style="background-color: #fff;"><i class="fa fa-facebook" style="color: #000;"></i></button>
                                                <button class="btn" style="background-color: #fff; margin-left: 10px;"><i class="fa fa-twitter" style="color: #000;"></i></button>
                                                <button class="btn" style="background-color: #fff; margin-left: 10px;"><i class="fa fa-linkedin" style="color: #000;"></i></button>
                                                <button class="btn" style="background-color: #fff; margin-left: 10px;"><i class="fa fa-instagram" style="color: #000;"></i></button>
                                                <button class="btn" style="background-color: #fff; margin-left: 10px;"><i class="fa fa-pinterest-p" style="color: #000;"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <br>
    <section>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7347.503206346943!2d76.04129607223582!3d22.959373418884326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396317847216388b%3A0xa5f1ce18a43655af!2sKalani%20Bagh%2C%20Gomti%20Nagar%2C%20Dewas%2C%20Madhya%20Pradesh%20455001!5e0!3m2!1sen!2sin!4v1619216945406!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </section>
</main>
<!-- footer -->
<app-footer></app-footer>