<!-- navigation bar -->
<app-navbar></app-navbar>
<main>
    <section>
        <div class="container-fluid" style="background: url(/assets/img/banner.jpg); height: max-content; background-repeat: no-repeat; background-size: cover;">
            <div class="row" style="min-height: 150px;">
                    <div class="mx-auto custom-control" style="margin-top: 50px;">
                        
                        <div class="form-box mx-auto">
                            <div class="input-group">
                                <input type="text" class="search-field" aria-label="Category" placeholder="Job Category">
                                <div class="input-group-append">
                                    <span class="input-group-text text-box"><i class="fa fa-chevron-down" aria-hidden="true"></i></span>
                                </div>
                                <input type="text" class="search-field" aria-label="Title" placeholder="Job Title">
                                <div class="input-group-append">
                                    <span class="input-group-text text-box"><i class="fa fa-search" aria-hidden="true"></i></span>
                                </div>
                                <input type="text" class="search-field" aria-label="Location" placeholder="City or State">
                                <div class="input-group-append">
                                    <span class="input-group-text text-box"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                                </div>
                                <div class="input-group-append"><button class="search-btn" type="button"><i class="fa fa-search" aria-hidden="true"></i> FIND</button></div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </section>
    <!-- <section>
        <div class="container-fluid" style="background-image: url(/assets/img/testibg.jpg);  background-repeat: no-repeat; background-size: cover;">
            <div class="row" style="min-height: 450px;">
                <div class="form-box mx-auto" style="margin-top: 240px;"> 
                    <div class="input-group">
                        
                        <div class="search-field">
                            <ng-autocomplete
                              [data]="jobcategory"
                              [searchKeyword]="keyword"
                              placeholder="Job Category"
                              (selected)='selectEvent($event)'
                              (inputChanged)='onChangeSearch($event)'
                              (inputFocused)='onFocused($event)'
                              historyIdentifier="jobcategory"
                              [itemTemplate]="itemTemplate"
                              [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>
                          
                            <ng-template #itemTemplate let-item>
                              <a [innerHTML]="item.name"></a>
                            </ng-template>
                          
                            <ng-template #notFoundTemplate let-notFound>
                              <div [innerHTML]="notFound"></div>
                            </ng-template>
                          </div>
                        <div class="input-group-append">
                            <span class="input-group-text text-box"><i class="fa fa-chevron-down" aria-hidden="true"></i></span>
                        </div>
                        
                        <div class="search-field">
                            <ng-autocomplete
                              [data]="jobtitle"
                              [searchKeyword]="keyword"
                              placeholder="Job Category"
                              (selected)='selectEvent($event)'
                              (inputChanged)='onChangeSearch($event)'
                              (inputFocused)='onFocused($event)'
                              historyIdentifier="jobtitle"
                              [itemTemplate]="itemTemplate"
                              [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>
                          
                            <ng-template #itemTemplate let-item>
                              <a [innerHTML]="item.name"></a>
                            </ng-template>
                          
                            <ng-template #notFoundTemplate let-notFound>
                              <div [innerHTML]="notFound"></div>
                            </ng-template>
                        </div>
                        <div class="input-group-append">
                            <span class="input-group-text text-box"><i class="fa fa-chevron-down" aria-hidden="true"></i></span>
                        </div>
                        
                        <div class="search-field">
                            <ng-autocomplete
                              [data]="joblocation"
                              [searchKeyword]="keyword"
                              placeholder="Job Category"
                              (selected)='selectEvent($event)'
                              (inputChanged)='onChangeSearch($event)'
                              (inputFocused)='onFocused($event)'
                              historyIdentifier="joblocation"
                              [itemTemplate]="itemTemplate"
                              [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>
                          
                            <ng-template #itemTemplate let-item>
                              <a [innerHTML]="item.name"></a>
                            </ng-template>
                          
                            <ng-template #notFoundTemplate let-notFound>
                              <div [innerHTML]="notFound"></div>
                            </ng-template>
                          </div>
                        <div class="input-group-append">
                            <span class="input-group-text text-box"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                        </div>
                        <button class="search-btn" type="button">Find <i class="fa fa-search" aria-hidden="true"></i></button>    
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <section>
        <!-- <div class="container-fluid" style="background-image: url(/assets/img/testibg.jpg); padding: 180px; background-repeat: no-repeat; background-size: cover;">
            <div class="row header-section">
                <h1 class="heading">Sales/Admin Support
                    <br><br>
                    <span style="color: #70da44;">(1-2 Yrs Exp.)</span> .</h1>
                <h3 class="sub-heading">Home > Job Listing > Details</h3>
            </div>
        </div> -->
        <div class="container" style="margin-top: 50px;">
            <ol class="breadcrumb breadcrumb-arrow">
                <li><a [routerLink]="['/home']">Home</a></li>
                <li><a [routerLink]="['/jobs']">Services</a></li>
                <li class="active"><span>Job Listing</span></li>
            </ol>
        </div>
    </section>
    <br>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-md-4" style="width: max-content;">
                    <div class="col-md-12">
                        <a type="button" class="btn" (click)="isCollapsed1 = !isCollapsed1" [attr.aria-expanded]="!isCollapsed1" aria-controls="collapseExample" style="font-weight: bold; color: #031a35;">Experience<span style="float: right; margin-left: 157px;"><i class="fa fa-chevron-down" aria-hidden="true"></i></span></a>
                        <div id="collapseExample" [ngbCollapse]="isCollapsed1">
                            <div class="card" style="border: none;">
                                <div class="card-body">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Fresher
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Less than 1 year
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            2 year
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            3 year
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            4 year
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-12">
                        <a type="button" class="btn" (click)="isCollapsed2 = !isCollapsed2" [attr.aria-expanded]="!isCollapsed2" aria-controls="collapseExample" style="font-weight: bold; color: #031a35;">Verified Profile<span style="float: right; margin-left: 130px;"><i class="fa fa-chevron-down" aria-hidden="true"></i></span></a>
                        <div id="collapseExample" [ngbCollapse]="isCollapsed2">
                            <div class="card" style="border: none;">
                                <div class="card-body">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Unverified
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Verified
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-12">
                        <a type="button" class="btn" (click)="isCollapsed3 = !isCollapsed3" [attr.aria-expanded]="!isCollapsed3" aria-controls="collapseExample" style="font-weight: bold; color: #031a35;">Types<span style="float: right; margin-left: 195px;"><i class="fa fa-chevron-down" aria-hidden="true"></i></span></a>
                        <div id="collapseExample" [ngbCollapse]="isCollapsed3">
                            <div class="card" style="border: none;">
                                <div class="card-body">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Freelance
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Full Time
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Part-Time
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-12">
                        <a type="button" class="btn" (click)="isCollapsed4 = !isCollapsed4" [attr.aria-expanded]="!isCollapsed4" aria-controls="collapseExample" style="font-weight: bold; color: #031a35;">Offered Salary<span style="float: right; margin-left: 135px;"><i class="fa fa-chevron-down" aria-hidden="true"></i></span></a>
                        <div id="collapseExample" [ngbCollapse]="isCollapsed4">
                            <div class="card" style="border: none;">
                                <div class="card-body">
                                    <!-- <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Per day
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Per hour/min
                                        </label>
                                    </div> -->
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            <div class="form-row">
                                                <div class="col">
                                                    Per Day
                                                </div>
                                                <div class="col">
                                                    <mat-slider style="position: relative; top:-15px; left: 42px;"
                                                    thumbLabel
                                                    [displayWith]="formatLabel"
                                                    tickInterval="1000"
                                                    step="1000"
                                                    min="0"
                                                    max="100000"
                                                    aria-label="units"></mat-slider>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
                                        <div class="form-row">
                                            <div class="col">
                                                Per Hour/ Min
                                            </div>
                                            <div class="col">
                                                <mat-slider style="position: relative; top:-15px;"
                                                thumbLabel
                                                [displayWith]="formatLabel"
                                                tickInterval="1000"
                                                step="1000"
                                                min="0"
                                                max="100000"
                                                aria-label="units"></mat-slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-12">
                        <a type="button" class="btn" (click)="isCollapsed5 = !isCollapsed5" [attr.aria-expanded]="!isCollapsed5" aria-controls="collapseExample" style="font-weight: bold; color: #031a35;">Profile Managed<span style="float: right; margin-left: 120px;"><i class="fa fa-chevron-down" aria-hidden="true"></i></span></a>
                        <div id="collapseExample" [ngbCollapse]="isCollapsed5">
                            <div class="card" style="border: none;">
                                <div class="card-body">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Self
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Agent
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-12">
                        <a type="button" class="btn" (click)="isCollapsed6 = !isCollapsed6" [attr.aria-expanded]="!isCollapsed6" aria-controls="collapseExample" style="font-weight: bold; color: #031a35;">Review<span style="float: right; margin-left: 186px;"><i class="fa fa-chevron-down" aria-hidden="true"></i></span></a>
                        <div id="collapseExample" [ngbCollapse]="isCollapsed6">
                            <div class="card" style="border: none;">
                                <div class="card-body">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                        <label class="form-check-label" for="flexCheckChecked">
                                            <div class="form-row">
                                                &nbsp;5<span style="color: #031a35; margin-left: 5px;">☆</span><span style="color: #031a35; margin-left: 5px;">☆</span><span style="color: #031a35; margin-left: 5px;">☆</span><span style="color: #031a35; margin-left: 5px;">☆</span><span style="color: #031a35; margin-left: 5px;">☆</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            <div class="form-row">
                                                &nbsp;4<span style="color: #031a35; margin-left: 5px;">☆</span><span style="color: #031a35; margin-left: 5px;">☆</span><span style="color: #031a35; margin-left: 5px;">☆</span><span style="color: #031a35; margin-left: 5px;">☆</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            <div class="form-row">
                                                &nbsp;3<span style="color: #031a35; margin-left: 5px;">☆</span><span style="color: #031a35; margin-left: 5px;">☆</span><span style="color: #031a35; margin-left: 5px;">☆</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            <div class="form-row">
                                                &nbsp;2<span style="color: #031a35; margin-left: 5px;">☆</span><span style="color: #031a35; margin-left: 5px;">☆</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            <div class="form-row">
                                                &nbsp;1<span style="color: #031a35; margin-left: 5px;">☆</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    
                    <div class="col-md-12">
                        <div class="col" style="position: relative; top: 5px;">
                            <button type="button" class="btn" style="color: #fff; 
                            background: #031a35; 
                            padding: 10px 25px; 
                            font-size: 15px;"><i class="fa fa-envelope" aria-hidden="true"></i> Get job alert</button>
                            <span style="float: right; margin-right: 85px; font-weight: bold; color: #031a35;">sort by: </span>
                            <div style="position: absolute; top: -10px; right: 0;">
                            
                            <select class="form-select" aria-label="Default select example">
                                <option selected>Newest</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                            </div>
                        </div>
                    </div>

                    <br>
                    <br>
                    
                    <div class="col-md-12">
                        <div class="media mx-auto shadow bg-white rounded animate__animated animate__fadeInRight animate__delay-1s" style="height: max-content; padding: 10px;">
                            <div class="align-self-start">
                                <img src="assets/img/prof1.jpg" alt="..." class="image-frame" width="200" height="200" style="position: relative;">
                                <button type="button" class="btn" style="color: #fff; background: #6edb45; font-size: 15px; position: absolute; left: 35px; bottom: 10px; width: 160px;"><i class="fa fa-long-arrow-right" aria-hidden="true"></i> VIEW PROFILE</button>
                            </div>
                            <!-- <img class="align-self-start mr-3" src="assets/img/person.jpg" alt="profile person"> -->
                            <div class="media-body"><br>
                                <h3 style="float: right; font-weight: 600;">&#8377; 10,000/-</h3>
                                <h3 class="card-title" style="font-weight: bold; color: #031a35; font-size: 20px;">George S.</h3>
                                <p>Many destop publishing packages and web page editors new use Lorem ipsum... <span style="color: #6edb45">More</span></p>
                                <p class="card-text" style="color: gray;">Sales/Admin Support</p>
                                <div class="form-check">
                                  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                  <label class="form-check-label" for="flexCheckChecked">
                                      737 Completed Tasks
                                  </label>
                                </div>
                                <div style="position: relative;">
                                  <div class="rating">
                                      <span style="color: #6edb45;">☆</span><span style="color: #6edb45;">☆</span><span style="color: #6edb45;">☆</span><span style="color: #6edb45;">☆</span><span style="color: #6edb45;">☆</span>
                                    </div>
                                    <p style="position: absolute; top: 0; margin-left: 100px;">100% positive</p><br>
                                </div>
                                <p style="color: #031a35; font-weight: bold;">Self</p>
                                <div class="form-group">
                                  <a class="small-btns btn-outline-primary">Assistant</a>
                                  <a class="small-btns btn-outline-primary">Receptionist</a>
                                  <a class="small-btns btn-outline-primary">PC</a>
                                </div>
                                <button type="button" class="btn" style="float: right; color: #fff; background: #031a35; font-size: 15px;"><i class="fa fa-phone" aria-hidden="true"></i> VIEW CONTACT</button>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-12">
                        <div class="media mx-auto shadow bg-white rounded animate__animated animate__fadeInRight animate__delay-1s" style="height: max-content; padding: 10px;">
                            <div class="align-self-start">
                                <img src="assets/img/prof1.jpg" alt="..." class="image-frame" width="200" height="200" style="position: relative;">
                                <button type="button" class="btn" style="color: #fff; background: #6edb45; font-size: 15px; position: absolute; left: 35px; bottom: 10px; width: 160px;"><i class="fa fa-long-arrow-right" aria-hidden="true"></i> VIEW PROFILE</button>
                            </div>
                            <!-- <img class="align-self-start mr-3" src="assets/img/person.jpg" alt="profile person"> -->
                            <div class="media-body"><br>
                                <h3 style="float: right; font-weight: 600;">&#8377; 10,000/-</h3>
                                <h3 class="card-title" style="font-weight: bold; color: #031a35; font-size: 20px;">George S.</h3>
                                <p>Many destop publishing packages and web page editors new use Lorem ipsum... <span style="color: #6edb45">More</span></p>
                                <p class="card-text" style="color: gray;">Sales/Admin Support</p>
                                <div class="form-check">
                                  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                  <label class="form-check-label" for="flexCheckChecked">
                                      737 Completed Tasks
                                  </label>
                                </div>
                                <div style="position: relative;">
                                  <div class="rating">
                                      <span style="color: #6edb45;">☆</span><span style="color: #6edb45;">☆</span><span style="color: #6edb45;">☆</span><span style="color: #6edb45;">☆</span><span style="color: #6edb45;">☆</span>
                                    </div>
                                    <p style="position: absolute; top: 0; margin-left: 100px;">100% positive</p><br>
                                </div>
                                <p style="color: #031a35; font-weight: bold;">3 Years Exp.</p>
                                <div class="form-group">
                                  <a class="small-btns btn-outline-primary">Assistant</a>
                                  <a class="small-btns btn-outline-primary">Receptionist</a>
                                  <a class="small-btns btn-outline-primary">PC</a>
                                </div>
                                <button type="button" class="btn" style="float: right; color: #fff; background: #031a35; font-size: 15px;"><i class="fa fa-phone" aria-hidden="true"></i> VIEW CONTACT</button>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-12">
                        <div class="media mx-auto shadow bg-white rounded animate__animated animate__fadeInRight animate__delay-1s">
                            <div class="mx-auto custom-control" style="background: #DADEED;">
                                <br>
                                <h3>Newly Added Profiles</h3>
                                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                    <ol class="carousel-indicators">
                                      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                                      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                    </ol>
                                    <div class="carousel-inner">
                                      <div class="carousel-item active">
                                        <div class="row">
                                            <div class="card-deck">
                                                <div class="card">
                                                  <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                                  <div class="card-body">
                                                        <p>Many desktop publishing packages and web...</p>
                                                        <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                                  </div>
                                                </div>
                                                <div class="card">
                                                  <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                                  <div class="card-body">
                                                    <p>Many desktop publishing packages and web...</p>
                                                    <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                                  </div>
                                                </div>
                                                <div class="card">
                                                  <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                                  <div class="card-body">
                                                    <p>Many desktop publishing packages and web...</p>
                                                    <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                                  </div>
                                                </div>
                                              </div>
                                        </div>
                                      </div>
                                      <div class="carousel-item">
                                        <div class="row">
                                            <div class="card-deck">
                                                <div class="card">
                                                  <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                                  <div class="card-body">
                                                        <p>Many desktop publishing packages and web...</p>
                                                        <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                                  </div>
                                                </div>
                                                <div class="card">
                                                  <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                                  <div class="card-body">
                                                    <p>Many desktop publishing packages and web...</p>
                                                    <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                                  </div>
                                                </div>
                                                <div class="card">
                                                  <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                                  <div class="card-body">
                                                    <p>Many desktop publishing packages and web...</p>
                                                    <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                                  </div>
                                                </div>
                                              </div>
                                        </div>
                                      </div>
                                      <div class="carousel-item">
                                        <div class="row">
                                            <div class="card-deck">
                                                <div class="card">
                                                  <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                                  <div class="card-body">
                                                        <p>Many desktop publishing packages and web...</p>
                                                        <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                                  </div>
                                                </div>
                                                <div class="card">
                                                  <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                                  <div class="card-body">
                                                    <p>Many desktop publishing packages and web...</p>
                                                    <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                                  </div>
                                                </div>
                                                <div class="card">
                                                  <img class="card-img-top" src="assets/img/prof1.jpg" alt="Card image cap">
                                                  <div class="card-body">
                                                    <p>Many desktop publishing packages and web...</p>
                                                    <a style="float: left; color: #6edb45;">View More</a> <p style="float: right; color: #6edb45;">&#8377; 15,000</p>
                                                  </div>
                                                </div>
                                              </div>
                                        </div>
                                      </div>
                                    </div>
                                    <a class="carousel-control-prev custom-carousel-control" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                      <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next custom-carousel-control" href="#carouselExampleIndicators" role="button" data-slide="next">
                                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                      <span class="sr-only">Next</span>
                                    </a>
                                  </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-12">
                        <div class="media mx-auto shadow bg-white rounded animate__animated animate__fadeInRight animate__delay-1s" style="height: max-content; padding: 10px;">
                            <div class="align-self-start">
                                <img src="assets/img/prof1.jpg" alt="..." class="image-frame" width="200" height="200" style="position: relative;">
                                <button type="button" class="btn" style="color: #fff; background: #6edb45; font-size: 15px; position: absolute; left: 35px; bottom: 10px; width: 160px;"><i class="fa fa-long-arrow-right" aria-hidden="true"></i> VIEW PROFILE</button>
                            </div>
                            <!-- <img class="align-self-start mr-3" src="assets/img/person.jpg" alt="profile person"> -->
                            <div class="media-body"><br>
                                <h3 style="float: right; font-weight: 600;">&#8377; 10,000/-</h3>
                                <h3 class="card-title" style="font-weight: bold; color: #031a35; font-size: 20px;">George S.</h3>
                                <p>Many destop publishing packages and web page editors new use Lorem ipsum... <span style="color: #6edb45">More</span></p>
                                <p class="card-text" style="color: gray;">Sales/Admin Support</p>
                                <div class="form-check">
                                  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                  <label class="form-check-label" for="flexCheckChecked">
                                      737 Completed Tasks
                                  </label>
                                </div>
                                <div style="position: relative;">
                                  <div class="rating">
                                      <span style="color: #6edb45;">☆</span><span style="color: #6edb45;">☆</span><span style="color: #6edb45;">☆</span><span style="color: #6edb45;">☆</span><span style="color: #6edb45;">☆</span>
                                    </div>
                                    <p style="position: absolute; top: 0; margin-left: 100px;">100% positive</p><br>
                                </div>
                                <p style="color: #031a35; font-weight: bold;">3 Years Exp.</p>
                                <div class="form-group">
                                  <a class="small-btns btn-outline-primary">Assistant</a>
                                  <a class="small-btns btn-outline-primary">Receptionist</a>
                                  <a class="small-btns btn-outline-primary">PC</a>
                                </div>
                                <button type="button" class="btn" style="float: right; color: #fff; background: #031a35; font-size: 15px;"><i class="fa fa-phone" aria-hidden="true"></i> VIEW CONTACT</button>
                            </div>
                        </div>
                    </div><br>
                </div>
            </div>
        </div>
    </section>
</main>
<!-- footer -->
<app-footer></app-footer>