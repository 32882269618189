import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrls: ['./editprofile.component.css']
})
export class EditprofileComponent implements OnInit {
  keyword = 'name';
  
  data = [{}];

  public skills = [
    {
      id: 1,
      name: 'Java',
    },
    {
      id: 2,
      name: 'PHP',
    },
    {
      id: 3,
      name: 'Node',
    },
    {
      id: 4,
      name: 'HTML',
    },
    {
      id: 5,
      name: 'CSS',
    },
    {
      id: 6,
      name: 'Angular CLI',
    },
    {
      id: 7,
      name: 'React',
    },
    {
      id: 8,
      name: 'jQuery',
    },
    {
      id: 9,
      name: 'AJAX',
    },
    {
      id: 10,
      name: 'JavaScript',
    },
    {
      id: 11,
      name: 'Laravel',
    },
    {
      id: 12,
      name: 'Python',
    }
  ];

  selectEvent(item: any) {
      this.data.push(item);
    console.log(this.data);
  }

  remove(i: any) {
    const index = this.data.indexOf(i, 0);
    if (index > -1) {
      this.data.splice(index, 1);
    }
  }

  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(item: any) {
  }

  constructor() { }

  ngOnInit(): void {
  }

}
