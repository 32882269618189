<!-- Navigation bar -->
<nav class="navbar fixed-top navbar-light" style="background: #171C2F;">
    <div class="container-fluid">
        <div>
            <a class="navbar-brand" href="#" style="color: #fff; font-weight: lighter; font-size: 15px;"><i class="fa fa-envelope-open" style="color: #70da44;"></i>&nbsp;companyinfo@gmail.com</a>
            <span class="navbar-text py-0" style="color: #fff; font-size: 15px;"><i class="fa fa-map-marker" style="color: #70da44;"></i>&nbsp;2AO, Queenstown St, USA</span>
        </div>
        <div>
            <span class="navbar-text"><a class="nav-link py-0" style="color: #fff;"><i class="fa fa-facebook"></i></a></span>
            <span class="navbar-text"><a class="nav-link py-0" style="color: #fff;"><i class="fa fa-twitter"></i></a></span>
            <span class="navbar-text"><a class="nav-link py-0" style="color: #fff;"><i class="fa fa-linkedin"></i></a></span>
            <span class="navbar-text"><a class="nav-link py-0" style="color: #fff;"><i class="fa fa-google-plus"></i></a></span>
            <span class="navbar-text"><a class="nav-link py-0" style="color: #fff;"><i class="fa fa-instagram"></i></a></span>
            <span class="navbar-text py-0" style="color: #fff; font-size: 15px;">
                <span style="color: #70da44;">Call us</span> 000 888 999
            </span>
        </div>
    </div>
</nav>
<nav class="navbar navbar-expand-md navbar-light mx-auto custom-nav">
    <div class="container">
        <img class="navbar-brand" src="/assets/img/logo.jpg" id="logo_custom" width="17%"  alt="logo">
        <button class="navbar-toggler navbar-toggler-right custom-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span class="navbar-toggler-icon "></span>
        </button>
        <div class="collapse navbar-collapse " id="collapsibleNavbar">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a class="nav-link active" aria-current="page" [routerLink]="['/home']">Why us</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Services
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                      <div class="row" style="height: max-content; width: 500px; padding: 10px; margin-left: 20px;">
                        <div class="col-md-3">
                            <p style="font-size: 16px; font-weight: 550;">Home Care</p>
                            <li>
                                <a class="links"><i class="fa fa-chevron-right" aria-hidden="true" style="font-weight: 200; font-size: smaller;"></i> Health Care</a>
                            </li>
                            <li>
                                <a class="links"><i class="fa fa-chevron-right" aria-hidden="true" style="font-weight: 200; font-size: smaller;"></i> Home Nursing</a>
                            </li>
                            <li>
                                <a class="links"><i class="fa fa-chevron-right" aria-hidden="true" style="font-weight: 200; font-size: smaller;"></i> Health Care</a>
                            </li>
                            <li>
                                <a class="links"><i class="fa fa-chevron-right" aria-hidden="true" style="font-weight: 200; font-size: smaller;"></i> Physiotherapy</a>
                            </li>
                        </div>
                        <div class="col" style="margin-left: 10px;">
                            <p style="font-size: 16px; font-weight: 550;">Finance / Account</p>
                            <li>
                                <a class="links"><i class="fa fa-chevron-right" aria-hidden="true" style="font-weight: 200; font-size: smaller;"></i> Health Care</a>
                            </li>
                            <li>
                                <a class="links"><i class="fa fa-chevron-right" aria-hidden="true" style="font-weight: 200; font-size: smaller;"></i> Home Nursing</a>
                            </li>
                            <li>
                                <a class="links"><i class="fa fa-chevron-right" aria-hidden="true" style="font-weight: 200; font-size: smaller;"></i> Accounting System</a>
                            </li>
                            <li>
                                <a class="links"><i class="fa fa-chevron-right" aria-hidden="true" style="font-weight: 200; font-size: smaller;"></i> Business tax return</a>
                            </li>  
                        </div>
                        <div class="col">
                            <p style="font-size: 16px; font-weight: 550;">Legal</p>
                            <li>
                                <a class="links"><i class="fa fa-chevron-right" aria-hidden="true" style="font-weight: 200; font-size: smaller;"></i> Sports Law</a>
                            </li>
                            <li>
                                <a class="links"><i class="fa fa-chevron-right" aria-hidden="true" style="font-weight: 200; font-size: smaller;"></i> Minority Rights</a>
                            </li>
                            <li>
                                <a class="links"><i class="fa fa-chevron-right" aria-hidden="true" style="font-weight: 200; font-size: smaller;"></i> GST</a>
                            </li>
                            <li>
                                <a class="links"><i class="fa fa-chevron-right" aria-hidden="true" style="font-weight: 200; font-size: smaller;"></i> Traffic laws</a>
                            <li>
                        </div>
                      </div>
                    </ul>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/contacts']">Get Buddy</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link">Invite a Friend</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/login']">Login</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/contact']">Contact Us</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Other links
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <li><a class="dropdown-item" [routerLink]="['/jobs']">Vendor Listing</a></li>
                        <li><a class="dropdown-item"  [routerLink]="['/details']">Vendor Details</a></li>
                        <!-- <li><a class="dropdown-item"  [routerLink]="['/findjobs']">Vendors Applied</a></li> -->
                        <li><a class="dropdown-item"  [routerLink]="['/blogs']">Blog</a></li>
                        <li><a class="dropdown-item"  [routerLink]="['/blog-detail']">Blog Details</a></li>
                        <li><a class="dropdown-item"  [routerLink]="['/subcategory-listing']">Subcategory Listing</a></li>
                        <li><a class="dropdown-item"  [routerLink]="['/pricing-table']">Pricing Table</a></li>
                        <li><a class="dropdown-item"  [routerLink]="['/referral']">Referral</a></li>
                        <li><a class="dropdown-item"  [routerLink]="['/job-post-listing']">Job Post Listing</a></li>
                        <li><a class="dropdown-item"  [routerLink]="['/employer-dashboard']">Employer Dashboard</a></li>
                        <li><a class="dropdown-item"  [routerLink]="['/employee-dashboard']">Employee Dashboard</a></li>
                        <li><a class="dropdown-item"  [routerLink]="['/agent-dashboard']">Agent Dashboard</a></li>
                    </ul>
                </li>
                <li>
                  <button type="button" class="btn search-btn" [routerLink]="['/form']">Post A Job</button>
                </li>
            </ul>
        </div>  
      </div>
</nav>